<template>
  <div>
    <a-form :form="form" @submit="handleSubmit">
      <a-row :gutter="20">
        <a-col :span="8" :offset="0">
          <a-form-item class="travel-input-outer">
            <label :class="form.getFieldValue(`name`) ? 'filled' : ''">
              Nome simplificado
            </label>

            <a-input
              class="travel-input"
              placeholder="Nome simplificado"
              v-decorator="[
                `name`,
                {
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
            >
              <a-icon slot="prefix" type="field-svg" />
            </a-input>
          </a-form-item>
        </a-col>

        <a-col :span="16">
          <a-form-item class="travel-input-outer">
            <label :class="form.getFieldValue(`company_name`) ? 'filled' : ''">
              Razão social
            </label>

            <a-input
              class="travel-input"
              placeholder="Razão social"
              v-decorator="[
                `company_name`,
                {
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
            >
              <a-icon slot="prefix" type="field-svg" />
            </a-input>
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item class="travel-input-outer">
            <label :class="form.getFieldValue(`trading_name`) ? 'filled' : ''">
              Nome Fantasia
            </label>

            <a-input
              class="travel-input"
              placeholder="Nome Fantasia"
              v-decorator="[
                `trading_name`,
                {
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
            >
              <a-icon slot="prefix" type="field-svg" />
            </a-input>
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item class="travel-input-outer">
            <label :class="form.getFieldValue(`cnpj`) ? 'filled' : ''">
              CNPJ
            </label>

            <a-input
              class="travel-input"
              placeholder="CNPJ"
              v-mask="'##.###.###/####-##'"
              v-decorator="[`cnpj`]"
            >
              <a-icon slot="prefix" type="field-svg" />
            </a-input>
          </a-form-item>
        </a-col>

        <a-col :span="24">
          <a-form-item class="travel-input-outer">
            <label :class="form.getFieldValue(`url`) ? 'filled' : ''">
              URL
            </label>

            <a-input
              class="travel-input"
              placeholder="URL"
              v-decorator="[`url`]"
            >
              <a-icon slot="prefix" type="field-svg" />
            </a-input>
          </a-form-item>
        </a-col>

        <a-col :span="24">
          <a-form-item style="display: none">
            <a-input
              placeholder="LOGO"
              v-decorator="[`logo`]"
              style="text-transform: none"
            >
            </a-input>
          </a-form-item>
        </a-col>

        <a-col class="a-center mt-20" :span="24">
          <a-button
            size="large"
            type="primary"
            :loading="loadingCreateAirline"
            html-type="submit"
          >
            CRIAR CIA
          </a-button>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>

<script>
export default {
  name: "EditDefaultDataAirline",
  props: {
    company_credit_cards: Array,
    edit: Boolean,
    cardId: Number,
  },
  data() {
    return {
      form: this.$form.createForm(this),
      loadingCreateAirline: false,
      airline: {},
    };
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        values.user_id = this.$store.state.userData.id;
        console.log(err, values);
        if (!err) {
          this.loadingCreateAirline = true;

          this.$http
            .post("/airline/create", values)
            .then(({ data }) => {
              this.$message.success(data.message);
              this.loadingCreateAirline = false;
              this.$emit("listAirlines", true);
            })
            .catch(({ response }) => {
              this.$message.error(response.data.message);
              this.loadingCreateAirline = false;
            });
        }
      });
    },
  },
};
</script>
